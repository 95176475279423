import React, { useReducer } from 'react'
import styled from "styled-components";
//@ts-ignore
import { RadioGroup, RadioButton } from './react-radio-buttons';
import HiddenSite from "./HiddenSite";
import { Body, Eyes, Head, Mouth, Neck } from './BodyParts';

const SContainer = styled.div`
  height: 100%;
  min-height: 200px;
  width:100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  word-break: break-word;

`;
const SPartContainer = styled.div`
  width: 20vw;
  min-height: 200px;
  margin-right: 4vw;
  margin-left: 4vw;
  font-size: 16px;
  @media (max-width: 755px) {
    font-size: 12px;
  }
  max-height: calc(100vh - 250px);
  overflow: scroll;
`;



const SH4 = styled.h4`
margin: 5px 0 15px 0;
    @media (max-width: 755px) {
    font-size: 16px;
  }
`

interface state{
    panda: number;
    backgroud: number;
    eyes: number;
    face: number;
    hair: number;
    neck: number;
}
const initialState:state = {
    panda: 0,
    backgroud: 4,
    eyes: 100,
    face: 100,
    hair: 100,
    neck: 100,

};
interface action{
    type: string,
    id: number | string 
}

const reducer = (state:state, action:action) => {
  switch (action.type) {
    case 'eyes':
        return {...state, eyes: action.id || 0};
    case 'face':
        return {...state, face: action.id || 0};
    case 'hair':
        return {...state, hair: action.id || 0};
    case 'neck':
        return {...state, neck: action.id || 0}
    case 'backgroud':
        return {...state, backgroud: action.id || 0}
    case 'panda':
        return {...state, panda: action.id || 0};
    default:
        throw new Error();
  }
}
const parts = {
    panda:[
        {
            id:0,
            name:'Cutie'},
            {
            id:1,
            name:'Chubby'},
                {
            id:2,
            name:'Chad'}

    ],
    eyes:[
        {
            id:100,
            name:'None'
            },
           {
            id:12,
            name:'Black'
            },
            {
            id:13,
            name:'Bug'
            },
            {
            id:11,
            name:'Crazy'
            },
            {
            id:0,
            name:'Dollars'
            },
            {
            id:9,
            name:'Heart'
            },
            {
            id:4,
            name:'MascaradeGreen'
            },
            {
            id:8,
            name:'MascaradeRed'
            },
            {
            id:2,
            name:'Monkey'
            },
            {
            id:5,
            name:'Peace'
            },
            {
            id:1,
            name:'Pig'
            },
            {
            id:6,
            name:'Pineapple'
            },
            {
            id:3,
            name:'Rainbow'
            },
            {
            id:14,
            name:'RedNoseGlasses'
            },
            {
            id:10,
            name:'Star'
            },
            {
            id:7,
            name:'Strawberry'
            },
            {
            id:15,
            name:'Wasted'
            },
            {
                id:16,
                name:'WastedWhite'
            }
    ],
    face:[
        {
            id:100,
            name:'None'
            },

        {
            id:3,
            name:'Cherry'
            },
            {
            id:2,
            name:'Chili'
            },
            {
            id:8,
            name:'Coconut'
            },
            {
            id:7,
            name:'Dog'
            },
            {
            id:11,
            name:'LickingLips'
            },
            {
            id:0,
            name:'Microphone'
            },
            {
            id:4,
            name:'Mustache'
            },
            {
            id:9,
            name:'PartyBlower'
            },
            {
            id:5,
            name:'PartyHorn'
            },
            {
            id:1,
            name:'Pig'
            },
            {
            id:6,
            name:'RedLipsSmile'
            },
            {
            id:10,
            name:'RedNoseMustache'
            },
            {
                id:12,
                name:'Mask'
            },
            {
                id:13,
                name:'MouthRedNose'
            }
    ],
    backgroud:[
        {   id:0,
            name: "Dark Blue",
            src: "DarkBlue.png"
        },
        {
            id:1,
            name: "Dark Pink",
            src: "DarkPink.png"
        },
        {
            id:2,
            name: "Green",
            src: "Green.png"
        },
        {
            id:3,
            name: "Hot Pink",
            src: "HotPink.png"
        },
        {
            id:4,
            name: "Light Blue",
            src: "LightBlue.png"
        },
        {
            id:5,
            name: "Pixel Rainbow",
            src: "PixelRainbow.png"
        },
        {
            id:6,
            name: "Trippy Green",
            src: "AbstractBlueGreenPurple.png"
        },
        {
            id:7,
            name: "Trippy Orange",
            src: "AbstractOrangeRed.png"
        },
        {
            id:8,
            name: "Trippy Purple",
            src: "AbstractRedPurple.png"
        },
        {
            id:9,
            name: "Trippy Yellow",
            src: "AbstractYellowOrange.png"
        },
        {
            id:10,
            name: "Yellow",
            src: "Yellow.png"
        },
    ],
    neck:[
        {
            id:100,
            name:'None'
            },
        {
            id:0,
            name:'BlackBow'
            },
            {
            id:2,
            name:'PinkBow'
            },
            {
            id:1,
            name:'RedBow'
            },
            {
                id:3,
                name:'GoldChain'
            },
            {
                id:4,
                name:'Pearl'
            },
            /*{
                id:5,
                name:'Scarf'
            }*/
    ],

    hair:[
        {
            id:100,
            name:'None'
            },
        {
            id:0,
            name:'Antlers'
},
{
id:1,
name:'BlueBaloon'
},
{
id:2,
name:'BunnyEars'
},
{
id:3,
name:'CrownSmall'
},
{
id:4,
name:'Frog'
},
{
id:5,
name:'GreenBaloon'
},
{
id:6,
name:'GreenYellowBaloon'
},
{
id:7,
name:'Horns'
},
{
id:8,
name:'Jester'
},
{
id:9,
name:'OrangeBaloon'
},
{
id:10,
name:'OrangeYellowBaloon'
},
{
id:11,
name:'PartyHatBlue'
},
{
id:12,
name:'PartyHatGreenYellow'
},
{
id:13,
name:'PartyHatMultiColor'
},
{
id:14,
name:'PartyHatOrangeYellow'
},
{
id:15,
name:'PartyHatPurple'
},
{
id:16,
name:'PartyHatRed'
},
{
id:17,
name:'PartyHatRedWhite'
},
{
id:18,
name:'Pirate'
},
{
id:19,
name:'PurpleBaloon'
},
{
id:20,
name:'RedBaloon'
},
{
id:21,
name:'RedFlowers'
},
{
id:22,
name:'RedTopHat'
},
{
id:23,
name:'Sombrero'
},
{
id:24,
name:'Unicorn'
},
{
id:25,
name:'Wizard'
},
{
id:26,
name:'YellowBaloon'
},
{
id:27,
name:'BlackOrangeTopHat'
},
{
id:28,
name:'Chef'
},
{
id:29,
name:'Eastern'
},
{
id:30,
name:'Jester'
},
{
id:31,
name:'Viking'
},
{
    id:32,
    name:'AntlerWhite'
},
{
    id:33,
    name:'Beanie'
},
{
    id:34,
    name:'Cat'
},
{
    id:35,
    name:'Chick'
},
{
    id:36,
    name:'Turtle'
},
{
    id:37,
    name:'Winter'
}
    ],

}


const PandaBuilder = () => {
//@ts-ignore
 const [state, dispatch] = useReducer(reducer, initialState);

 const setPart = (type:string, id:number) => {
    console.log(type)
    console.log(state.hair === "Party.png" && state.eyes === "Wasted.png");
//@ts-ignore
    dispatch({type, id})
 }
 const SBuilderContainer = styled.div`
  margin-top:50px;
  height: 40vw;
  width: 40vw;
  background:url('${"/PandaParty/Background/"+parts.backgroud[state.backgroud].src}') center/40vw 40vw no-repeat !important;
  min-height: 100%;
  position:relative;
`;

  return (
      <SContainer>
            <div></div>
             {state.hair === "Party.png" && state.eyes === "Wasted.png" && <HiddenSite/>}
            <SPartContainer>
                {Object.keys(parts).map((keyName:string, i) => (
                    i < 4 && 
                        <>
                            <SH4>{keyName}</SH4>
                            <RadioGroup onChange={ (event:number) => setPart(keyName, event) }>
                                {
                                    //@ts-ignore
                                    parts[keyName].map((part:any) => (
                                        <RadioButton iconSize={20} iconInnerSize={10} value={part.id} pointColor={"#15e3e7"} rootColor={"white"} padding={5}>
                                            {part.name}
                                        </RadioButton>
                                ))}
                            </RadioGroup>
                        </>
                ))}
            </SPartContainer>
            <SBuilderContainer>
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 400 400" style={{ width: "inherit", height:"inherit"}} xmlSpace="preserve">
                {(state.neck === 3 || state.neck === 4) && <Neck {...{id:state.neck}}/>}
                <Body {...{id:state.panda}}/>
                <Head {...{id:state.hair}}/>
                <Eyes {...{id:state.eyes}}/>
                {state.neck !== 3 && state.neck !== 4 && <Neck {...{id:state.neck}}/>}
                <Mouth {...{id:state.face}}/>
            </svg>
                

                
                
                
            </SBuilderContainer>
            <SPartContainer>
            {Object.keys(parts).map((keyName:string, i) => (
                i >= 4 && 
                    <>
                        <SH4>{keyName}</SH4>
                        <RadioGroup onChange={ (event:number) => setPart(keyName, event) }>
                            {
                                //@ts-ignore
                                parts[keyName].map((part:any) => (
                                    <RadioButton iconSize={20} iconInnerSize={5} pointColor={"#15e3e7"} rootColor={"white"} value={part.id} padding={5}>
                                        {part.name}
                                    </RadioButton>
                            ))}
                        </RadioGroup>
                    </>
            ))}
            </SPartContainer>
      </SContainer>
)}

export default PandaBuilder   
