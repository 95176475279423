import * as React from "react";
import styled from "styled-components";

import Web3Modal from "web3modal";

import Column from "./components/Column";
import Wrapper from "./components/Wrapper";
import Header from "./components/Header";
import PandaBuilder from "./components/PandaBuilder";
import SimpleReactLightbox from 'simple-react-lightbox'

const localStorageKey = 'AppHome__activeTab';

const SLayout = styled.div`
  position: relative;
  width: 100%;
  min-height: 100vh;
  text-align: center;
  cursor: url("/pawopen.png"), pointer;
`;

const SContent = styled(Wrapper)`
  width: 100%;
  height: 100%;
  padding: 0;
`;

const SLanding = styled(Column)`
  height: 600px;
`;
const SColumn = styled(Column)`
  min-width: 100%;
`;


// @ts-ignore
const SBalances = styled(SLanding)`
  height: 100%;
  & h3 {
    padding-top: 30px;
  }
`;

const STestButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

const STest = styled.div`
  margin-top: 50px;
  font-size: 27px;
  line-height: 2.5;
`;

const Tabs = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom:20px;
  z-index: 99;
`;
const Tab = styled.div`
  margin: 10px;
  font-size:20px;
  font-weight:bold;
  padding-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;
  &:hover {
    cursor: url("/paw.png"), pointer;
    opacity: 0.8;
  }
  &.active {
    border-bottom: 2px solid black;
  }
`;

interface IAppState {
  showModal: boolean;
  activeTab: string;
}

const INITIAL_STATE: IAppState = {
  showModal: false,
  activeTab: localStorage.getItem(localStorageKey) || "0"
};

class App extends React.Component<any, any> {
  // @ts-ignore
  public web3Modal: Web3Modal;
  public state: IAppState;

  constructor(props: any) {
    super(props);
    this.state = {
      ...INITIAL_STATE
    };
  }


  public setTab = (activeTab: string) => {
        
    localStorage.setItem(localStorageKey, activeTab);
    this.setState({
      activeTab
    });
  }


  public render = () => {
    console.log("Oh you naughty naughty panda... back down here again?");
    console.log('Fine, ill give you some of the good stuff... DM me on twitter https://twitter.com/SirPandaParty. Message me "GIVE ME A FREE PANDA FUCKER B12"');
    return (
    <SimpleReactLightbox>
      <SLayout>
        <SColumn spanHeight>
          <Header
          />
          <Tabs>
            <Tab className={this.state.activeTab === "1" ? "active" : ""} onClick={() => this.setTab("1")}>Panda.Me</Tab>
            <Tab className={this.state.activeTab === "0" ? "active" : ""} onClick={() => this.setTab("0")}>Panda Builder</Tab>
          </Tabs>
          <SContent>
            {this.state.activeTab === "1" ? (
              <SBalances>
                <Column center>
                  <STestButtonContainer>
                    <STest>
                    Are you ready to get this party started?  Soon, all pandas will be able to mint one free Party Panda! These pandas will be customizable with over 100 different accessories. But be careful party people, once you add an accessory to your panda, this will become a permanent piece of your party attire. Make sure you dress to impress and maybe you’ll get an invite to the VIP lounge.
                    </STest>
                  </STestButtonContainer>
                </Column>
              </SBalances>
            ) : (

                <PandaBuilder />

            )}
          </SContent>
        </SColumn>
      </SLayout>
    </SimpleReactLightbox>
    );
  };
}

export default App;
