import React from 'react';
import { HairJester, HairAntlers, HairPirate, HairHorns, HairBunnyEars, HairPartyHatOrangeYellow, HairPartyHatGreenYellow, HairCrownSmall, HairRedTopHat, HairPartyHatPurple, HairPartyHatBlue, HairPartyHatMultiColor, HairPartyHatRed, HairWizard, HairRedFlowers, HairFrog, HairPartyHatRedWhite, HairOrangeBaloon, HairRedBaloon, HairYellowBaloon, HairGreenBaloon, HairGreenYellowBaloon, HairBlueBaloon, HairOrangeYellowBaloon, HairPurpleBaloon, HatChef, HatEastern, HatViking, HatBlackOrangeTopHat, HatJester, HairSombrero, HairUnicorn, Mouth3, EyeRight3, EyeLeft3, Body3, EyeRight2, EyeLeft2, Mouth2, Body2, EyeRight1, EyeLeft1, Mouth1, MouthMicrophone, MouthPig, MouthChili, MouthCherry, MouthMustache, MouthPartyHorn, MouthRedLipsSmile, MouthDog, MouthCoconut, MouthPartyBlower, MouthRedNoseMustache, MouthLickingLips, EyesDollars, EyesPig, EyesMonkey, EyesRainbow, EyesMascaradeGreen, EyesPeace, EyesPineapple, EyesStrawberry, EyesMascaradeRed, EyesHeart, EyesStar, EyesCrazyEyes, EyesBlack, EyesBug, EyesRedNoseGlasses, EyesWasted, NeckBlackBow, NeckRedBow, NeckPinkBow, Body1, HairAntlerWhite, HairBeanie, HairCat, HairChick, HairTurtle, HairWinter, NeckGoldChain, NeckPearl, NeckScarf, EyesWastedWhite, MouthMask, MouthRedNose } from './SvgBuilder';

//@ts-ignore
export const Head = ({id}) => {

    return (
        <>
        {
        id === 0 ?          <HairAntlers />:
  
        id === 1 ?  <HairBlueBaloon /> : 
        id === 2 ?          <HairBunnyEars />: 

        id === 3 ?          <HairCrownSmall />: 

        id === 4 ?          <HairFrog />: 
        id === 5 ?          <HairGreenBaloon />: 

        id === 6 ?  <HairGreenYellowBaloon /> : 

        id === 7 ?          <HairHorns />: 
        id === 8 ?  <HairJester />: 
        id === 9 ?          <HairOrangeBaloon />  : 
        id === 10 ?  <HairOrangeYellowBaloon /> : 

        id === 11 ?          <HairPartyHatBlue />: 
        id === 12 ?          <HairPartyHatGreenYellow />: 
        id === 13 ?          <HairPartyHatMultiColor />: 
        id === 14 ?          <HairPartyHatOrangeYellow />: 
        id === 15 ?          <HairPartyHatPurple />: 
        id === 16 ?          <HairPartyHatRed />: 
        id === 17 ?          <HairPartyHatRedWhite /> : 

        id === 18 ?          <HairPirate />: 
        id === 19 ?  <HairPurpleBaloon /> : 
        id === 20 ?          <HairRedBaloon /> : 
        id === 21 ?          <HairRedFlowers />: 
        id === 22 ?          <HairRedTopHat />: 
        id === 23 ?          <HairSombrero /> : 


        id === 24 ? <HairUnicorn /> :

        id === 25 ?          <HairWizard />: 
        id === 26 ?          <HairYellowBaloon /> : 
        id === 27 ?          <HatBlackOrangeTopHat /> : 

        id === 28 ?          <HatChef /> : 
        id === 29 ?          <HatEastern /> : 
        id === 30 ?          <HatJester /> :
        id === 31 ?          <HatViking /> : 
        id === 32 ?          <HairAntlerWhite /> : 
        id === 33 ?          <HairBeanie /> : 
        id === 34 ?          <HairCat /> : 
        id === 35 ?          <HairChick /> : 
        id === 36 ?          <HairTurtle /> : 
        id === 37 ?          <HairWinter /> : 













 


        <></>

        }
        
        </>
    )
}
//@ts-ignore
export const Body = ({id}) => {
    return (
        <>
        {
        id === 0 ? <>        
            <Body3 /> 
            <Mouth3 /> 
            <EyeRight3 /> 
            <EyeLeft3 /> 
        </> : 
        id === 1 ? <>

            <Body2 />
            <EyeRight2 /> 
            <EyeLeft2 /> 
            <Mouth2 /> 
        </>   :
        <>
            <Body1 />
            <EyeRight1 /> 
            <EyeLeft1 /> 
            <Mouth1 /> 
        </> 
        }
        
        </>
    )
}
//@ts-ignore
export const Mouth = ({id}) => {
    return (
        <>
        {
        id === 0 ? <MouthMicrophone /> : 
        id === 1 ?  <MouthPig />:
        id === 2 ?  <MouthChili />: 
        id === 3 ?  <MouthCherry />: 
        id === 4 ?  <MouthMustache />: 
        id === 5 ?  <MouthPartyHorn />: 
        id === 6 ?  <MouthRedLipsSmile /> : 
        id === 7 ?  <MouthDog /> : 
        id === 8 ?  <MouthCoconut /> : 
        id === 9 ?  <MouthPartyBlower />: 
        id === 10 ? <MouthRedNoseMustache />:
        id === 11 ? <MouthLickingLips />:
        id === 12 ? <MouthMask />:
        id === 13 ? <MouthRedNose />:
        <></>

        }
        
        </>
    )
}
//@ts-ignore
export const Eyes = ({id}) => {
    return (
        <>
        {
        id === 0 ?  <EyesDollars />: 
        id === 1 ?  <EyesPig />:
        id === 2 ?  <EyesMonkey />: 
        id === 3 ?  <EyesRainbow />: 
        id === 4 ?  <EyesMascaradeGreen />: 
        id === 5 ?  <EyesPeace />: 
        id === 6 ?  <EyesPineapple /> : 
        id === 7 ?  <EyesStrawberry /> : 
        id === 8 ?  <EyesMascaradeRed /> : 
        id === 9 ?   <EyesHeart /> : 
        id === 10 ?  <EyesStar /> : 
        id === 11 ?  <EyesCrazyEyes /> : 
        id === 12 ?  <EyesBlack />: 
        id === 13 ?  <EyesBug />: 
        id === 14 ?  <EyesRedNoseGlasses />: 
        id === 15 ?  <EyesWasted />: 
        id === 16 ?  <EyesWastedWhite />: 
        <></>

        }
        
        </>
    )
}
//@ts-ignore
export const Neck = ({id}) => {
    return (
        <>
        {
        id === 0 ?  <NeckBlackBow />: 
        id === 1 ?  <NeckRedBow />:
        id === 2 ?  <NeckPinkBow />: 
        id === 3 ?  <NeckGoldChain />: 
        id === 4 ?  <NeckPearl />: 
        id === 5 ?  <NeckScarf />: 
        <></>

        }
        
        </>
    )
}