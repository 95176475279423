import * as React from "react";
import styled from "styled-components";
import Icon from "./Icon";
import twitter from "../assets/twitter.png"
import discord from "../assets/discord.png"
import telegram from "../assets/telegram.png"
import SuppressWarnings from "suppress-warnings";
import { Body, Eyes, Head, Mouth, Neck } from "./BodyParts";
 
// this is all the same, see JavaScript version for details
 
SuppressWarnings([
    //@ts-ignore
    (warning, name, ctor) => nameyarn  === "DeprecationWarning" && warning.toString() === "OutgoingMessage.prototype._headers is deprecated"
]);

const Slogo = styled.div`
  flex-direction: column;
  font-family: Chalkduster, fantasy;
  color: #b4fff4;
  font-size: 64px;
  align-self: center;
  padding-top: 30px;
  @media (max-width: 755px) {
    display: none;
  }
`;

const SImg = styled.div`
  flex-direction: column;
  text-align: left;
  align-items: flex-start;
  max-height: 150px;
  margin:auto;
  padding-right: 10px;  
  & > svg {
    max-height: 150px!important;
    max-width: 150px!important;
  }
  @media (max-width: 755px) {

  & > svg {
    max-height: 175px!important;
    max-width: 175px!important;
  }
}
  -webkit-transition: 60s ease-in-out;
	transition: 60s ease-in-out;

  &:hover {
    transform: rotate(90000deg) rotateY(110000deg) rotateX(2000deg) rotateZ(2000deg);
    cursor: url("/paw.png"), pointer;
    transform-style: preserve-3d;
    
  }  
`;

const SlogoContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 610px;
  margin-top:  0px;
  align-items: center;
  @media (max-width: 755px) {
    min-width: 100%;
    margin-top: 40px;
    margin-bottom: 20px;
  }
`;


const SHeader = styled.div`
  margin-top: -1px;
  margin-bottom: 1px;
  width: 100%;
  align-items: center;
  flex-wrap:wrap;
  justify-content: space-between;
  padding: 0 16px;
`;


const SLink = styled.a`
  margin-left:20px;
  &:hover{
    cursor: url("/paw.png"), pointer;
  }
`;

const SIcon = styled(Icon)`
  width:30px;
  height:24px;
`;

const SSocialContainer = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 20px;
  right: 20px;
  font-weight: 500;
`;


const Header = () => {

  return (
    <SHeader >
      <SlogoContainer>
        
        <SImg >
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 400 400" style={{//@ts-ignore 
            enableBackground: 'new 0 0 400 400'}} xmlSpace="preserve" width={400} height={400}>
            <Body {...{id:Math.floor(Math.random() * 3)}}/>
            <Head {...{id:Math.floor(Math.random() * 26)}}/>
            <Eyes {...{id:Math.floor(Math.random() * 32)}}/>
            <Neck {...{id:Math.floor(Math.random() * 3)}}/>
            <Mouth {...{id:Math.floor(Math.random() * 20)}}/>
          </svg>
        </SImg>
        <Slogo>Panda Party</Slogo>
      </SlogoContainer>
     
      <SSocialContainer>
        <SLink href="https://twitter.com/PandaPartyVIP" target="_blank">
          <SIcon src={twitter} />
        </SLink>
        <SLink href="https://discord.gg/UtAX4AEJPT"  target="_blank">
          <SIcon src={discord} />
        </SLink>
        <SLink href="https://t.me/PandaPartyOfficial"  target="_blank">
          <SIcon src={telegram} />
        </SLink>
      </SSocialContainer>
    </SHeader>
  );
};


export default Header;
