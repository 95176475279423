import React, { useState, useEffect } from 'react'
import hidden from "../assets/hidden.png";

import styled from "styled-components";

const Simg = styled.img`
  height: 100%;
  min-height: 100px;
  min-width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  word-break: break-word;
  &:hover{
      transform: rotate(110000deg);
      cursor: url("/paw.png"), pointer;
      transform-style: preserve-3d;
      -webkit-transition: 1000s linear;
      transition: 1000s linear;
      
    }  
  }
`;



const CardsContainer = () => {
    const [randX, setRandX] = useState(Math.floor(Math.random()*90));
    const [randY, setRandY] = useState(Math.floor(Math.random()*60));

    useEffect(() => {
        let timer = setTimeout(() => {
            setRandX(Math.floor(Math.random()*90));
            setRandY(Math.floor(Math.random()*60));
        }, 5000) 
        return () => {
            clearTimeout(timer);
          };
    },[randX])

    return (
        <a style={{
            position: 'fixed',
            top: randX + "vh",
            left: randY + "vw",
            zIndex: 999999999,
            width: "50px",
            height: "50px"
        }} href="https://pandaparty.vip" target="_blank" rel="noreferrer">
            <Simg src={hidden} />
        </a>
    )
    
}

export default CardsContainer   
